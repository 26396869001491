<template>
  <div class="container">
    <div class="blog">
      <div class="text-block" v-html="post.body"></div>
    </div>
    <div class="pagination">
      <div class="previous" @click="previous()">Ankstesnis</div>
      <div class="next" @click="next()">Kitas</div>
    </div>
  </div>
</template>
<script>
import ProjectsFile from "../data/projects.json";

export default {
  data() {
    return {
      projects: ProjectsFile,
      post: {},
      posts: [],
    };
  },
  metaInfo() {
    return {
      title: `MonaArchus - ${this.post.data.title}`,
      meta: [
        {
          name: "description",
          content: this.post.data.shortDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://www.monaarchus.com/news/${this.post.id}`,
        },
      ],
    };
  },
  created() {
    this.postId = this.$route.params.post;
    this.post = this.getPost(this.postId);
    this.posts = this.getAllPosts();
  },
  methods: {
    getPost(postId) {
      return this.projects.news.posts.find(
        (p) => p.id.localeCompare(postId) == 0
      );
    },
    getAllPosts() {
      return this.projects.news.posts.sort((a, b) =>
        a.data.date > b.data.date ? -1 : 1
      );
    },
    previous() {
      var currentPost = this.posts.find(
        (p) => p.id.localeCompare(this.postId) == 0
      );
      var currentPostIndex = this.posts.indexOf(currentPost);
      var navigateTo = this.posts[this.posts.length - 1].id;
      if (currentPostIndex > 0) {
        navigateTo = this.posts[currentPostIndex - 1].id;
      }
      window.location.href = navigateTo;
    },
    next() {
      var currentPost = this.posts.find(
        (p) => p.id.localeCompare(this.postId) == 0
      );
      var currentPostIndex = this.posts.indexOf(currentPost);
      var navigateTo = this.posts[0].id;
      if (currentPostIndex < this.posts.length - 1) {
        navigateTo = this.posts[currentPostIndex + 1].id;
      }
      window.location.href = navigateTo;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/style/pages/blogPost";
</style>