<template>
  <m-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5,
    }"
    min-height="200"
    transition="fade-transition"
    @click="navigate(item.id)"
    class="project-card"
  >
    <div class="image-holder">
      <img
        v-if="thumbnailDimensionsExist"
        :src="thumbnail"
        :width="thumbnailWidth"
        :height="thumbnailHeight"
        :title="item.data.title"
        :alt="item.data.title"
      />
      <img
        v-else
        :src="thumbnail"
        :title="item.data.title"
        :alt="item.data.title"
      />
      <div class="image-hover-overlay">
        <h2>{{ item.data.title }}</h2>

        <template v-if="Array.isArray(item.data.tags)">
          <div v-for="tag in item.data.tags" :key="tag" class="tag">
            {{ tag }}
          </div>
        </template>
        <div v-else class="tag">{{ item.data.tags }}</div>
      </div>
    </div>
  </m-lazy>
</template>

<script>
import MLazy from "./custom/MLazy/MLazy";

export default {
  name: "ProjectCard",
  components: { MLazy },

  props: {
    item: Object,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    navigate(id) {
      this.$router.push(`/project/${id}`);
    },
  },
  computed: {
    thumbnail() {
      return this.item.data.thumbnail;
    },
    thumbnailDimensionsExist() {
      return this.item.data.thumbnailDimensions;
    },
    thumbnailWidth() {
      return this.item.data.thumbnailDimensions.width;
    },
    thumbnailHeight() {
      return this.item.data.thumbnailDimensions.height;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/components/projectCard";
</style>
