<template>
  <div class="desktop-navigation container">
    <a href="/">
      <img class="logo" width="146" height="70" src="../assets/LOGO.svg" />
    </a>
    <div class="menu">
      <a
        v-for="route in routes"
        :key="route.url"
        :href="`/${route.url}`"
        class="menu-item"
        >{{ route.title }}</a
      >
    </div>
  </div>
</template>

<script>
import ProjectsFile from "../data/projects.json";

export default {
  name: "Header",
  data() {
    return {
      pageObjects: ProjectsFile,
      routes: [],
    };
  },
  created() {
    this.routes = Object.entries(this.pageObjects)
      .filter(([k, v]) => v.enabled)
      .map(([k, v]) => ({ url: k, title: v.routeName }));
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/components/header";
</style>
