<template>
  <footer>
    <div class="container">
      <div class="holder">
        <a href="/">
          <img
            class="logo"
            width="130"
            height="76"
            src="../assets/LOGO-white.svg"
          />
        </a>
      </div>
      <socials />
      <span>All rights reserved, 2021</span>
    </div>
  </footer>
</template>

<script>
import Socials from "./Socials.vue";
export default {
  components: { Socials },
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "../assets/style/components/footer";
</style>
