<template>
  <div class="container">
    <h1>MonaArchus rašiniai:</h1>
    <div class="blog-posts">
      <a
        class="post"
        v-for="post in blogPosts"
        :key="post.id"
        :href="'news/' + post.id"
      >
        <div
          class="image-container"
          v-bind:style="getPostImageStyle(post)"
        ></div>
        <div class="post-data">
          <h2 class="title">{{ post.data.title }}</h2>
          <span class="description">{{ post.data.shortDescription }}</span>
          <div class="bottom">
            <span class="date">{{ post.data.date }}</span>
            <span class="tag" v-for="tag in post.data.tags" :key="tag">{{
              tag
            }}</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import ProjectsFile from "../data/projects.json";

export default {
  data() {
    return {
      id: 0,
      projects: ProjectsFile,
      blogPosts: [{}],
    };
  },
  metaInfo() {
    return {
      title: "MonaArchus - Rašiniai",
      meta: [
        {
          name: "description",
          content:
            "Susipažinkite su interjero naujienomis bei kurkite savo svajonių namus pasitelkę praktinius patarimus apie užuolaidas ir namų dekoravimą tekstilės gaminiais.",
        },
      ],
      link: [{ rel: "canonical", href: "https://www.monaarchus.com/news" }],
    };
  },
  created() {
    this.blogPosts = this.projects.news.posts.sort((a, b) =>
      a.data.date > b.data.date ? -1 : 1
    );
  },
  methods: {
    getPostImageStyle(post) {
      return {
        backgroundImage: `url(${post.data.thumbnail})`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/blog";
</style>