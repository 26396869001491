<template>
  <div class="conatct-form" id="contacts">
    <div class="container">
      <div class="text-container">
        <h3>Turite klausimų?</h3>
        <h4>Bendraukime</h4>
      </div>

      <div class="form-container" :class="{ centered: success || failure }">
        <form
          class="what"
          :class="{ invisible: success || failure }"
          ref="form"
          @submit.prevent="checkForm"
        >
          <label for="name">Vardas:</label><br />
          <input
            class="input-field"
            :class="{ error: nameError }"
            type="text"
            id="name"
            name="user_name"
            v-model="name"
          /><br />

          <label for="message">Žinutė:</label><br />
          <textarea
            class="input-field"
            :class="{ error: messageError }"
            type="text"
            id="message"
            name="message"
            v-model="message"
          /><br />

          <label for="email">El. paštas</label><br />
          <input
            class="input-field"
            :class="{ error: emailError }"
            type="text"
            id="email"
            name="user_email"
            v-model="email"
          /><br />
          <input class="button" type="submit" value="Siųsti" />
        </form>
        <div
          class="message-holder"
          :class="{ invisible: !success && failure }"
          ref="success-holder"
        >
          <div class="message success">
            <span class="title">Puiku, žinutė išsiųsta!</span>
            <span class="text"
              >Į jūsų užklausą atsakysime per kelias dienas.</span
            >
          </div>
        </div>
        <div
          class="message-holder"
          :class="{ invisible: success && !failure }"
          ref="failure-holder"
        >
          <div class="message failure">
            <span class="title">Kažkas ne taip...</span>
            <span class="text"
              >Žinutės išsiųsti nepavyko, gal pabandykite dar kartą?</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import { init } from "emailjs-com";

export default {
  name: "Header",
  data() {
    return {
      serviceId: "contact_form",
      templateId: "template_61crr2l",
      userId: "user_Ku01LJgsIZJlQDzR5lECF",
      success: false,
      failure: false,
      name: "",
      message: "",
      email: "",
      nameError: false,
      messageError: false,
      emailError: false,
      submited: false,
    };
  },
  watch: {
    name() {
      this.nameError = this.submited && !this.isNameValid();
    },
    message() {
      this.messageError = this.submited && !this.isMessageValid();
    },
    email() {
      this.emailError = this.submited && !this.isEmailValid();
    },
  },
  created() {
    init(this.userId);
  },
  methods: {
    checkForm() {
      this.submited = true;
      this.nameError = !this.isNameValid();
      this.messageError = !this.isMessageValid();
      this.emailError = !this.isEmailValid();
      if (this.isNameValid() && this.isMessageValid() && this.isEmailValid()) {
        this.sendEmail();
      }
    },
    sendEmail() {
      emailjs
        .sendForm(this.serviceId, this.templateId, this.$refs.form, this.userId)
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.success = true;
            this.failure = false;
            this.name = "";
            this.message = "";
            this.email = "";
            this.submited = false;
            this.resetFormafter();
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.success = false;
            this.failure = true;
            this.resetFormafter();
          }
        );
    },
    resetFormafter(miliseconds = 2000) {
      setTimeout(() => {
        this.success = false;
        this.failure = false;
      }, miliseconds);
    },
    isNameValid() {
      return this.name.length > 3;
    },
    isMessageValid() {
      return this.message.length > 3;
    },
    isEmailValid() {
      return this.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/components/form";
</style>
