<template>
  <div class="container">
    <h1>MonaArchus paslaugos</h1>
    <div class="services">
      <div class="left">
        <div class="block">
          <h2>Interjero projektavimas</h2>
          <span>
            <strong>1 etapas</strong>&nbsp;konsultacija, interjero idėjos,
            koncepcijos sukūrimas.
          </span>
          <span
            ><strong>2 etapas</strong>&nbsp;pasiūlymai, išplanavimo
            eskizai.</span
          >
          <span
            ><strong>3 etapas</strong>&nbsp;planų, išklotinių pateikimas, baldų
            gamybos brėžiniai, apdailos medžiagų bei spalvų parinkimas.</span
          >
        </div>

        <div class="block">
          <h2>Apšvietimo projektavimas</h2>
          <span>2D elektros ir apšvietimo brėžiniai</span>
        </div>
        <div class="block">
          <h2>Baldų projektavimas</h2>
          <span
            >2D kietųjų baldų konstrukciniai brėžiniai (išklotinės, planai,
            pjūviai)</span
          >
        </div>
        <div class="block">
          <h2>Medžiagų / baldų / interjero detalių parinkimas</h2>
        </div>
        <div class="block">
          <h2>Konsultacija</h2>
        </div>
        <div class="block">
          <h2>Diena su dizaineriu</h2>
          <span
            >Paslauga tiems, kas įsirenginėja namus savo jėgomis, tačiau norėtų
            profesionalaus interjero specialisto pagalbos. (Pagalba derinant
            apdailos medžiagas, tekstūras, spalvas, renkantis baldus, šviestuvus
            ir kt. 6val.)</span
          >
        </div>
        <div class="block">
          <h2>Interjero tekstilės projektavimas, siuvimas</h2>
          <span
            >Pirminė konsultacija apžiūrint objektą, matavimai. Galimų interjero
            tekstilės variantų parinkimas, jų derinimas su užsakovu, siuvimo
            brėžinių parengimas. Dekoravimo įgyvendinimo priežiūra
            objekte.</span
          >
        </div>
      </div>
      <div class="right"><contact-box /></div>
    </div>
  </div>
</template>
<script>
import ContactBox from "../components/ContactBox.vue";
export default {
  components: { ContactBox },
  metaInfo() {
    return {
      title: "MonaArchus - Paslaugos",
      meta: [
        {
          name: "description",
          content:
            "Profesionali architektė / tekstilės dizainerė pilnam Jūsų interjero projektui ar apšvietimo, kietųjų baldų brėžiniams parengti, medžiagų, baldų, interjero detalių parinkimui, konsultacijoms.  ",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.monaarchus.com/services",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/services";
</style>