<template>
  <div class="container">
    <div class="contacts">
      <div class="description">
        <div class="text-block">
          <h1>Architektė - Dizainerė Monika Bartkutė - Blažė</h1>
          <div><b>Telefonas: </b> <span>+37063849592</span></div>
          <div><b>E-paštas: </b> <span>monika@monaarchus.com</span></div>
          <div>
            <b>Instagram: </b>
            <a href="https://www.instagram.com/mona_archus/" target="blank"
              >mona_archus</a
            >
          </div>
          <div>
            <b>Behance: </b>
            <a href="https://www.behance.net/monaarchus" target="blank"
              >/monaarchus</a
            >
          </div>
          <div>
            <b>Adresas: </b>
            <a target="blank" href="https://goo.gl/maps/i5NT9GetWfMvGfjP9"
              >Raudondvario pl. 101A-3, Kaunas, Studija Nr. 31</a
            >
          </div>
        </div>
        <div class="text-block">
          <h2>Darbo laikas</h2>
          <div><b>I - V </b> <span>10:00 - 19:00</span></div>
          <div><b>VI </b> <span>10:00 - 14:00</span></div>
        </div>
      </div>
      <div class="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2293.8572743022255!2d23.873660415897792!3d54.90542728033521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e7238bbf2fb483%3A0xb13c7ea3ebbc53ea!2s%22MonaArchus%22%20interjero%20dizaino%20ir%20tekstil%C4%97s%20studija!5e0!3m2!1sen!2slt!4v1643757419944!5m2!1sen!2slt"
          width="800"
          height="600"
          style="border: 0"
          allowfullscreen="1"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "MonaArchus - Kontaktai",
      meta: [
        {
          name: "description",
          content:
            "Susisiekime visais interjero projektavimo ir interjero tekstilės kūrimo klausimais bei konsultacijoms. ",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.monaarchus.com/contacts",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/pages/contacts";
</style>
