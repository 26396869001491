<template>
  <div class="container">
    <h1>Inspiracijos</h1>
    <span class="subtext"
      >Lietuvos ir užsienio interjero dizainerių darbai, padėsiantys perteikti
      artimą interjero ir tekstilės dizaino stilistiką.</span
    >
    <gallery :list="list" card-type="Photo" />
  </div>
</template>

<script>
import ProjectsFile from "../data/projects.json";
import Gallery from "../components/generic/Gallery.vue";

export default {
  name: "Home",
  components: {
    Gallery,
  },
  data() {
    return {
      list: [],
      projects: ProjectsFile,
    };
  },
  metaInfo() {
    return {
      title: "MonaArchus - Inspiracijos",
      meta: [
        {
          name: "description",
          content:
            "Lietuvos ir užsienio interjero dizainerių darbai, padėsiantys perteikti artimą kūrybinę, interjero ir tekstilės dizaino stilistiką.",
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.monaarchus.com/inspiration" },
      ],
    };
  },
  created() {
    this.list = this.projects.inspiration.list;
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/inspiration";
</style>
