<template>
  <div class="socials">
    <a
      :class="social.name"
      v-for="social in socials"
      :key="social.name"
      :href="social.url"
      target="blank"
    >
      <img
        height="30"
        width="30"
        :src="`/images/icons/socials/${social.name}.svg`"
      />
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      socials: [
        {
          name: "instagram",
          url: "https://www.instagram.com/mona_archus/",
        },
        {
          name: "behance",
          url: "https://www.behance.net/monaarchus",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/components/socials";
</style>
