import { render, staticRenderFns } from "./ContactBox.vue?vue&type=template&id=343c8318&scoped=true"
var script = {}
import style0 from "./ContactBox.vue?vue&type=style&index=0&id=343c8318&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343c8318",
  null
  
)

export default component.exports