<template>
  <div class="container">
    <carousel class="carousel" :perPage="1" loop>
      <slide v-for="(image, index) in project.images" :key="index">
        <img
          :alt="project.data.title"
          :src="image"
          :width="thumbnailWidth"
          :height="thumbnailHeight"
        />
      </slide>
    </carousel>
    <div class="project">
      <div class="description">
        <div class="text-block">
          <h1>{{ project.data.title }}</h1>
          <p v-html="project.body"></p>
        </div>
      </div>
      <div class="gallery">
        <img
          v-for="(image, index) in project.images"
          :key="index"
          :title="project.data.title"
          :alt="project.data.title"
          :src="image"
          :width="thumbnailWidth"
          :height="thumbnailHeight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsFile from "../data/projects.json";
import Carousel from "../components/custom/Carousel/Carousel.vue";
import Slide from "../components/custom/Carousel/Slide.vue";

export default {
  components: { Carousel, Slide },
  data() {
    return {
      projects: ProjectsFile.gallery.projects,
      project: {},
    };
  },
  metaInfo() {
    return {
      title: `MonaArchus - ${this.project.data.title}`,
      meta: [
        {
          name: "description",
          content: this.project.data.shortDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://www.monaarchus.com/project/${this.project.id}`,
        },
      ],
    };
  },
  created() {
    this.project = this.getProjectById(this.$route.params.id);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getProjectById(id) {
      var project = this.projects.find((p) => p.id == id);
      if (project) {
        return project;
      }
      window.location.href = "/404";
    },
  },
  computed: {
    thumbnailWidth() {
      return this.project.data.thumbnailDimensions
        ? this.project.data.thumbnailDimensions.width
        : "";
    },
    thumbnailHeight() {
      return this.project.data.thumbnailDimensions
        ? this.project.data.thumbnailDimensions.height
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/project";
</style>