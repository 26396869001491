<template>
  <div class="filter">
    <span
      v-for="topic in topics"
      :key="topic"
      class="topic link"
      @click="select(topic)"
      :ref="topic"
      >{{ topic }}</span
    >
  </div>
</template>

<script>
export default {
  name: "FilterBar",
  props: {
    topics: [],
    current: null,
  },
  updated() {
    this.select(this.current);
  },
  methods: {
    select(topic) {
      for (var ref in this.$refs) {
        this.$refs[ref][0].classList.remove("active");
      }
      this.$refs[topic][0].classList.add("active");

      this.$emit("selected", topic);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/components/filterBar";
</style>
