<template>
  <div class="mobile-navigation">
    <div class="navbar container">
      <a href="/">
        <img class="logo" width="51" height="30" src="../assets/LOGO.svg" />
      </a>
      <span class="icon" @click="toggleMenu()">
        <img
          class="logo"
          width="28"
          height="32"
          src="../assets/hamburger.svg"
        />
      </span>
    </div>
    <div class="mobile-menu" ref="mobile-menu">
      <a
        v-for="route in routes"
        :key="route.url"
        :href="`/${route.url}`"
        class="menu-item"
        >{{ route.title }}</a
      >
    </div>
    <div v-if="menuOpen" class="menu-overlay" @click="toggleMenu()"></div>
  </div>
</template>

<script>
import ProjectsFile from "../data/projects.json";

export default {
  name: "HeaderMobile",
  data() {
    return {
      menuOpen: false,
      pageObjects: ProjectsFile,
      routes: [],
    };
  },
  created() {
    this.routes = Object.entries(this.pageObjects)
      .filter(([k, v]) => v.enabled)
      .map(([k, v]) => ({ url: k, title: v.routeName }));
  },
  methods: {
    toggleMenu() {
      var menu = this.$refs["mobile-menu"];
      if (menu.className === "mobile-menu") {
        menu.className += " open";
        this.menuOpen = true;
        document.addEventListener("touchmove", this.toggleMenu, false);
        document.addEventListener("scrol", this.toggleMenu);
        return;
      }
      menu.className = "mobile-menu";
      document.removeEventListener("touchmove", this.toggleMenu, false);
      document.removeEventListener("scrol", this.toggleMenu);
      this.menuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/components/headerMobile";
</style>
