import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import vueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false

Vue.use(VueMeta);
Vue.use(vueSmoothScroll)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
