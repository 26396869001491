<template>
  <div>
    <FilterBar
      v-if="filter"
      :topics="topics"
      :current="currentTopic"
      @selected="filterCards"
    />
    <div class="gallery">
      <div class="column" :key="rowIndex" v-for="(row, rowIndex) in matrix">
        <div class="cell" :key="item.id" v-for="item in row">
          <component :item="item" :is="cardType" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FilterBar from "../FilterBar.vue";
import ProjectCard from "../ProjectCard.vue";
import Photo from "../Photo.vue";

export default {
  props: {
    list: Array,
    cardType: String,
    filter: Boolean,
  },
  components: {
    FilterBar,
    ProjectCard,
    Photo,
  },

  data() {
    return {
      breakpoints: { sm: 360, md: 768, lg: 1366 },
      mainTopic: "Visi projektai",
      columnsUpdated: 0,
      currentTopic: "",
      matrix: [],
      topics: [],
      columns: 3,
    };
  },
  created() {
    this.topics.push(this.mainTopic);
    this.handleScreenResize();
    window.addEventListener("resize", this.handleScreenResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleScreenResize);
  },
  mounted() {
    this.topics = this.queryTopics();
    this.matrix = this.listToMatrix(this.list, this.columns);
    this.currentTopic = this.mainTopic;
    if (this.$route.query.category) {
      this.currentTopic = this.$route.query.category;
    }
    this.filterCards(this.currentTopic);
  },
  methods: {
    filterCards(topic) {
      topic = decodeURI(topic);
      this.addQueryParam(topic);
      if (topic == "undefined" || topic === this.mainTopic) {
        this.matrix = this.listToMatrix(this.list, this.columns);
        this.removeQueryParam();
        return;
      }
      var filteredProjects = [...this.list].filter((project) =>
        project.data.tags.includes(topic)
      );
      this.matrix = this.listToMatrix(filteredProjects, this.columns);
    },
    addQueryParam(topic) {
      history.pushState({}, null, this.$route.path + "?category=" + topic);
    },
    removeQueryParam() {
      history.pushState({}, null, this.$route.path.split("?")[0]);
    },
    queryTopics() {
      return this.topics.concat(
        this.uniq(this.list.flatMap((project) => project.data.tags))
      );
    },
    handleScreenResize() {
      var w = window.screen.width;
      if (w > 0 && w <= this.breakpoints.sm) {
        this.columnsUpdated = 1;
      } else if (w > this.breakpoints.sm && w <= this.breakpoints.md) {
        this.columnsUpdated = 1;
      } else if (w > this.breakpoints.md && w <= this.breakpoints.lg) {
        this.columnsUpdated = 2;
      } else {
        this.columnsUpdated = 3;
      }

      if (this.columns != this.columnsUpdated) {
        this.columns = this.columnsUpdated;
        this.matrix = this.listToMatrix(this.list, this.columns);
      }
    },
    listToMatrix(a, n) {
      var len = a.length,
        out = [],
        i = 0,
        size;

      if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
          out.push(a.slice(i, (i += size)));
        }
      } else {
        while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, (i += size)));
        }
      }

      return out;
    },
    uniq(list) {
      var seen = [];
      return list.filter((item) => {
        return seen.includes(item) ? false : seen.push(item);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/components/gallery";
</style>
