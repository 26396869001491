import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Contacts from '../pages/Contacts.vue'
import About from '../pages/About.vue'
import Project from '../pages/Project.vue'
import Blog from '../pages/Blog.vue'
import Post from '../pages/BlogPost.vue'
import Services from '../pages/Services.vue'
import Inspiration from '../pages/Inspiration.vue'
import PageNotFound from '../pages/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/contacts',
		name: 'Contacts',
		component: Contacts
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/project/:id',
		name: 'Project',
		component: Project
	},
	{
		path: '/news',
		name: 'News',
		component: Blog
	},
	{
		path: '/news/:post',
		name: 'Post',
		component: Post
	},
	{
		path: '/services',
		name: 'Services',
		component: Services
	},
	{
		path: '/inspiration',
		name: 'Inspiration',
		component: Inspiration
	},
	{ path: "/404", component: PageNotFound },
	{ path: "*", component: PageNotFound }
]

const router = new VueRouter({
	mode: "history",
	routes
})

export default router
