<template>
  <div class="container">
    <!-- <h1>Monaarchus projektai</h1> -->

    <gallery class="mb-100" filter :list="list" card-type="ProjectCard" />
    <h2>Klientų atsiliepimai</h2>
    <review-carousel :reviews="projects.gallery.reviews" :perPage="3" />
  </div>
</template>

<script>
import ProjectsFile from "../data/projects.json";
import Gallery from "../components/generic/Gallery.vue";
import ReviewCarousel from "../components/ReviewCarousel.vue";

export default {
  name: "Home",
  components: {
    Gallery,
    ReviewCarousel,
  },
  data() {
    return {
      list: [],
      projects: ProjectsFile,
    };
  },
  metaInfo() {
    return {
      title: "MonaArchus | Interjero dizainas ir tekstilė",
      meta: [
        {
          name: "description",
          content:
            "Harmonija ir estetika namuose suteikia pridėtinę vertę kasdienybei. Monaarchus - interjero dizaino ir tekstilės projektavimo paslaugos išskirtinei Jūsų namų istorijai sukurti!",
        },
      ],
      link: [{ rel: "canonical", href: "https://www.monaarchus.com/" }],
    };
  },
  created() {
    this.list = this.projects.gallery.projects;
  },
};
</script>
