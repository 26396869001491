<template>
  <div id="app">
    <HeaderMobile v-if="mobile" />
    <Header v-else />
    <router-view />
    <Form />
    <Footer />
    <div class="bottom-border"></div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import HeaderMobile from "./components/HeaderMobile.vue";
import Footer from "./components/Footer.vue";
import Form from "./components/Form.vue";

export default {
  name: "App",
  components: {
    Header,
    HeaderMobile,
    Form,
    Footer,
  },
  data() {
    return {
      mobile: false,
    };
  },
  created() {
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      var w = window.screen.width;
      if (w <= 768) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
  },
};
</script>

<style lang="scss">
$mediaMobile: 576px;
$mediaTablet: 768px;
$mediaLaptop: 992px;

html {
  font-family: "Montserrat", Helvetica, "FS sans", sans-serif;
  background-color: white;
}

.button {
  border: none;
  background-color: #3a3838;
  padding: 16px 32px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 700;
  color: white !important;
  text-decoration: none;

  &:hover {
    background-color: #8d3c23;
    text-decoration: none;
    color: white;
  }
}

body {
  margin: 0 !important;
  background-color: #f6f6f6 !important;
  .bottom-border {
    display: none;
  }

  &.bordered {
    @media (min-width: $mediaLaptop) {
      margin: 20px;
    }
    background-color: #f6f6f6;
    position: relative;
    padding: 0;
    margin: 0;

    .bottom-border {
      display: none;
      @media (min-width: $mediaLaptop) {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        height: 20px;
      }
    }
  }
}

h1 {
  font-size: 32px;
  margin-bottom: 16px;
  @media (min-width: $mediaLaptop) {
    font-size: 40px;
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 16px;
}

h3 {
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  text-align: left;
}

p {
  font-size: 16px;
  line-height: 1.7;
  padding-bottom: 16px;
}

a {
  color: black;
}

li {
  padding-left: 0;
  line-height: 1.7;
}

a,
.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #8d3c23;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

.mb-100 {
  margin-bottom: 100px;
}

img {
  width: 100%;
}

.overlay {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  border: solid white 24px;
  box-sizing: border-box;
  pointer-events: none;
}

#app {
  color: #3a2620;
}

#nav {
  padding: 30px;

  & a {
    font-weight: bold;
    color: #3a2620;
  }

  &.router-link-exact-active {
    color: #42b983;
  }
}

@mixin mobile {
  @media (min-width: 0px) and (max-width: #{$mediaMobile}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mediaMobile}) and (max-width: #{$mediaTablet - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$mediaTablet}) and (max-width: #{$mediaLaptop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$mediaLaptop}) {
    @content;
  }
}

@include mobile {
  body {
  }
}

@include tablet {
  body {
  }
}

@include desktop {
  body {
  }
}
</style>
