<template>
  <div class="container">
    <div class="about">
      <div class="description">
        <h2>Labas, aš Monika</h2>
        <p>
          Estetika ir dizainu domėjausi nuo mažų dienų. Atverta močiutės audinių
          spinta žadėjo kelionę į neaprėpiamą fantazijų pasaulį, o palėpė - vis
          naują ir naują jaukumo išsipildymą. Žaidimas “Kaip atskiri erdvės
          elementai sąveikauja tarpusavyje ir sukuria jausmą, kad čia gyventi
          gera” tapo mano kasdienybe. Intuityviai tapau ‘katinu‘, kuris jaučia,
          kur jaukiausias namų kampas ar kaip jį tokiu paversti. Galimybė
          išspręsti ir kūrybiškai pažiūrėti į iš pirmo žvilgsnio neįveikiamą
          jaukumo užduotį mane žavi labiausiai. Visuomet siekiu suprasti ir
          sukurti harmoniją ir estetiką bet kuriam objektui ar erdvei, nesvarbu,
          ar tai būtų namai, biuras, ar gražiai padengtas stalas, prie kurio
          susirenka draugai ar šeima; Harmonija, darna ir estetika sukuria
          pridėtinę vertę kasdienybei.<br /><br />
          Žinios, kurias įgijau studijuodama architektūrą, ir metai, kai erdvių
          kūrimo meno galėjau mokytis praktiniu būdu dirbdama architektų G.
          Natkevičiaus, R. Paleko ir V.Adomavičiaus komandose, iš esmės praplėtė
          mano akiratį. Skonis, stiliaus pajautimas, erdvių zonavimas, medžiagų
          tarpusavio darna, ryšio, paremto bendradarbiavimu, kūrimas su
          klientais – visa tai susidėjau į savo bagažą. Architektūra, interjero
          dizainas, namų jaukumo kūrimas man dabar yra ne tik daug žinių ir
          techninių įgūdžiu reikalaujantis procesas; tai tapo ir sugebėjimu
          suprasti, išjausti ir įsigilinti į kiekvieno žmogaus namų istoriją,
          padėti jai sužydėti...
        </p>
      </div>
      <div class="image-container">
        <img width="680" height="454" src="/images/about/Monika.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "MonaArchus - Apie",
      meta: [
        {
          name: "description",
          content:
            "Profesionali architektė / tekstilės dizainerė, padėsianti sukurti jaukumą namuose. Svarbiausia vertybė - išjausti kiekvieno namų istoriją, padėti jai sužydėti.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.monaarchus.com/about",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/about";
</style>
