<template>
  <m-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5,
    }"
    min-height="200"
    transition="fade-transition"
    class="project-card"
  >
    <div class="image-holder">
      <img
        :src="item.data.thumbnail"
        :title="item.data.title"
        :alt="item.data.title"
      />
    </div>
  </m-lazy>
</template>

<script>
import MLazy from "./custom/MLazy/MLazy";

export default {
  name: "Photo",
  components: { MLazy },
  props: {
    item: Object,
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>
