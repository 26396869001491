<template>
  <div class="container">
    <div class="not-found">
      <div class="code">Nepavyko rasti šio puslapio</div>
      <div class="text">
        Labai atsiprašome, kad nepavyko rasti puslapio, kurio ieškote
      </div>
      <a class="button" href="/">Grįžti į pradinį puslapį</a>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "MonaArchus - Nerastas puslapis",
      meta: [
        {
          name: "description",
          content: "404 - Atsiprašome, bet puslapis nerastas",
        },
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/pages/pageNotFound";
</style>
