<template>
  <carousel
    class="carousel"
    :perPage="perPageMod"
    :navigationEnabled="navigationEnabled"
    :paginationEnabled="false"
    :scrollPerPage="false"
    :navigationClickTargetSize="20"
    navigationPrevLabel="<svg width='30' height='58' viewBox='0 0 30 58' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M29.5 0.5L1 29L29.5 57.5' stroke='#3A3838'/></svg>"
    navigationNextLabel="<svg width='31' height='58' viewBox='0 0 31 58' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 0.5L29.5 29L1 57.5' stroke='#3A3838'/></svg>"
  >
    <slide v-for="(review, index) in reviews" :key="index">
      <div class="box">
        <div class="upper">
          <span class="title">{{ review.title }}</span>
          <span class="text">{{ review.text }}</span>
        </div>
        <span class="date">{{ review.date }}</span>
      </div>
    </slide>
  </carousel>
</template>
<script>
import Carousel from "./custom/Carousel/Carousel.vue";
import Slide from "./custom/Carousel/Slide.vue";

export default {
  components: { Carousel, Slide },
  props: {
    reviews: Array,
    perPage: Number,
  },
  data() {
    return {
      breakpoints: { sm: 360, md: 768, lg: 1366 },
      perPageMod: 1,
      navigationEnabled: true,
    };
  },
  created() {
    this.handleScreenResize();
    window.addEventListener("resize", this.handleScreenResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleScreenResize);
  },
  methods: {
    handleScreenResize() {
      var w = window.screen.width;
      if (w > 0 && w <= this.breakpoints.sm) {
        this.perPageMod = 1;
        this.navigationEnabled = false;
      } else if (w > this.breakpoints.sm && w <= this.breakpoints.md) {
        this.perPageMod = 1;
        this.navigationEnabled = false;
      } else if (w > this.breakpoints.md && w <= this.breakpoints.lg) {
        this.navigationEnabled = false;
        this.perPageMod = 2;
      } else {
        this.navigationEnabled = true;
        this.perPageMod = 3;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/components/reviewCarousel";
</style>